import React from 'react';
import { NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { useGccConfigurator } from '../../hooks/useGccConfigurator';

export const Pcp = () => {
  const { component } = useGccConfigurator();
  return (
    <>
      <NoIndexNoFollowMetadata />
      { component }
    </>
  );
};

Pcp.propTypes = {
};

Pcp.displayName = 'Pcp';

Pcp.defaultProps = {
  name: '',
};
